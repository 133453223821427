import "./latmpoc.css";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { LatmPocInterface, SplitCardInterface, UserCardInterface } from "../../interface/redux-state/LatmPocInterface";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState, KeyboardEvent } from "react";
import { isFirefox } from "react-device-detect";
import {
  resetStateAndError,
  changeStateError,
  setCvv,
  setCardType,
  loading,
  updateError,
  setProcessing,
  resetForNewCard,
} from "../../redux-store/reducer/latmPocSlice";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import dayjs from "dayjs";
import PaymentService from "../../service/paymentService";
import { initializeCpgmui, resetCpgmui } from "../../redux-store/reducer/cpgmuiSlice";
import { classNames, extractRequestParameters, getCardType, getIframeUrl, loadCybersourceJS } from "../../utils/utils";
import { Cpgmui, CpgmuiEvents } from "../../utils/Cpgmui";
import { contextLoadedAction, microformInitializedAction } from "../../redux-store/reducer/paymentSlice";
import axios, { AxiosError } from "axios";
import { createCybersourceToken } from "../../utils/cybersourceUtil";
import { getYearsFromToday, isCybersourceTimedout, getTimedoutDummyToken } from "../../utils/calendarUtil";
import { formatAsCurrency } from "../../utils/numberUtil";
import { LatmpocSplitCardComponent } from "./latmpocSplitCardComponent";

const errorFlags = {
  ccExpExpired: false,
  customerNameMissing: false,
  customerFatherNameMissing: false,
  customerMotherNameMissing: false,
  creditCardMissing: false,
  creditCardInvalid: false,
  cvvMissing: false,
  cvvInvalid: false,
  amountMissing: false,
  amountValueError: false,
  amountTotalError: false,
};
// const APP_NAME = "LATMPOC";

const SERVICE_OPERATION = {
  profileAdd: "profileAdd",
};

type Microform = {
  microform: (options: any) => any;
};
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
    "font-size": "8pt",
    "font-family": '"Arial", sans-serif',
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
} as React.CSSProperties;

const inputStyle = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
} as React.CSSProperties;

var creditCardMicroNumber: any;
var cvvMicroSecurityCode: any;
const browserStartTime = new Date();
const currentMonth = browserStartTime.toLocaleString("en-US", { month: "2-digit" });
const currentYear = browserStartTime.toLocaleString("en-US", { year: "numeric" });

export function LatmPocComponent() {
  const latmPocState = useSelector((state: any) => state.latmpoc) as LatmPocInterface;
  const cpgmuiState = useSelector((state: any) => state.cpgmui);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);
  const dispatch = useDispatch();
  const cpgmuiRef = useRef<Cpgmui>();
  const setupTestDataRef = useRef<boolean>(false);
  const setupCcExpYearRef = useRef<boolean>(false);
  const balanceAmountRef = useRef<string>("");
  const customerNameRef = useRef<HTMLInputElement>(null);
  const [microFormLoadErrorMessage, setMicroFormLoadErrorMessage] = useState("");
  const buttonClickRef = useRef<boolean>(false);
  const userCardsRef = useRef<UserCardInterface[]>([]);
  const splitCardsRef = useRef<SplitCardInterface[]>([]);
  const numberOfSplitCardsRef = useRef<string>("0");
  const maxSplitCardsRef = useRef<boolean>(false);

  const { appKey } = useParams();
  //* initialize iframe -----------------------------
  useEffect(() => {
    const processRequest = async (req: any) => {
      const resp = await PaymentService.getDecryptRequest(req.app, req.encryptedRequestKey, req.encryptedRequest);
      req.unencryptedRequest = resp.data;
      cpgmuiRef.current = new Cpgmui(req.unencryptedRequest["tcepps.proxyUrl"]);
      balanceAmountRef.current = req.unencryptedRequest["pno.balanceAmount"];
      dispatch(initializeCpgmui(req));
      if (!setupCcExpYearRef.current) {
        try {
          getYearsFromToday(11, document.getElementById("ccExpYear") as HTMLSelectElement, false);
          setupCcExpYearRef.current = true;
        } catch (error) {}
      }
      return () => {
        dispatch(resetCpgmui());
        dispatch(resetStateAndError());
      };
    };

    const requestParameters = extractRequestParameters(appKey || "");
    processRequest(requestParameters).then(() => {
      cpgmuiRef.current?.iframeInitialized();
    });
  }, [dispatch, appKey]);

  const checkPayAmount = useCallback((): boolean => {
    const payAmount = Number(latmPocState.amount).toFixed(2).toLocaleString();
    const balanceAmount = Number(latmPocState.balanceAmount.replace("$", "")).toFixed(2).toLocaleString();
    if (!payAmount || isNaN(parseFloat(payAmount))) {
      return false;
    }
    if (!balanceAmount || isNaN(parseFloat(balanceAmount))) {
      return false;
    }
    if (parseFloat(payAmount) > parseFloat(balanceAmount)) {
      return false;
    }
    return true;
  }, [latmPocState.amount, latmPocState.balanceAmount]);

  //* message handler --------------------------------
  const validateForm = useCallback(
    (checkAmount: boolean) => {
      let temp = errorFlags;
      let hasError = false;
      if (!latmPocState.customerName || latmPocState.customerName.trim().length === 0) {
        temp = { ...temp, customerNameMissing: true };
        hasError = true;
      }
      if (!latmPocState.customerFatherName) {
        temp = { ...temp, customerFatherNameMissing: true };
        hasError = true;
      }
      if (!latmPocState.cardBrand) {
        temp = { ...temp, creditCardInvalid: true };
        hasError = true;
      } else if (!latmPocState.cardValid) {
        temp = { ...temp, creditCardMissing: true };
        hasError = true;
      }
      if (!latmPocState.cvvValid) {
        temp = { ...temp, cvvMissing: true, cvvInvalid: true };
        hasError = true;
      } else if (!latmPocState.cvvEmpty) {
        temp = { ...temp, cvvMissing: true };
        hasError = true;
      }

      const expirationMonth = Number(latmPocState.ccExpMonth);
      const expirationYear = Number(latmPocState.ccExpYear);
      const currentMonth = dayjs().month() + 1;
      const currentYear = dayjs().year();

      if (
        !expirationMonth ||
        !expirationYear ||
        currentYear > expirationYear ||
        (currentYear === expirationYear && currentMonth > expirationMonth)
      ) {
        temp = { ...temp, ccExpExpired: true };
        hasError = true;
      }

      if (latmPocState.payChoice === "PAYANOTHERAMOUNT") {
        if (latmPocState.amount === "") {
          temp = { ...temp, amountMissing: true };
          hasError = true;
        } else if (!checkPayAmount()) {
          temp = { ...temp, amountValueError: true };
          hasError = true;
        }
      }

      if (!hasError && checkAmount) {
        if (latmPocState.payChoice === "PAYANOTHERAMOUNT") {
          // check if the total is balance amount
          let totalAmount = "0.0";
          // total previous card balance
          if (userCardsRef.current && userCardsRef.current.length > 0) {
            for (let idx = 0; idx < userCardsRef.current.length; idx++) {
              if (userCardsRef.current[idx]["payChoice"] === "PAYANOTHERAMOUNT") {
                totalAmount = Number(parseFloat(totalAmount) + parseFloat(userCardsRef.current[idx]["amount"]))
                  .toFixed(2)
                  .toLocaleString();
              } else {
                totalAmount = Number(parseFloat(totalAmount) + parseFloat(userCardsRef.current[idx]["balanceAmount"]))
                  .toFixed(2)
                  .toLocaleString();
              }
            }
          }
          // total up current amount latmPocState.amount
          totalAmount = Number(parseFloat(totalAmount) + parseFloat(latmPocState.amount ? latmPocState.amount : "0.0"))
            .toFixed(2)
            .toLocaleString();
          if (totalAmount !== balanceAmountRef.current) {
            temp = { ...temp, amountTotalError: true };
            hasError = true;
          }
        }
      }

      if (hasError) {
        dispatch(updateError(temp));
      }
      return {
        hasError: hasError,
        errors: temp,
      };
    },
    [dispatch, latmPocState, checkPayAmount]
  );

  const buildCardTable = useCallback(
    (jti: string, lastFourDigits: string, bin: string) => {
      let amount = Number(latmPocState.amount).toFixed(2).toLocaleString();
      const balanceAmount = Number(latmPocState.balanceAmount.replace("$", "")).toFixed(2).toLocaleString();
      const changedState: { [index: string]: string | any } = { ...latmPocState };

      numberOfSplitCardsRef.current = (Number(latmPocState.numberOfSplitCards) + 1).toLocaleString();
      changedState["numberOfSplitCards"] = numberOfSplitCardsRef.current;
      if (numberOfSplitCardsRef.current > "1") {
        maxSplitCardsRef.current = true;
      }
      const newCardDetails: UserCardInterface = {
        cardBrand: latmPocState.cardBrand,
        ccAccountNum: jti,
        lastFourDigits: lastFourDigits,
        bin: bin,
        ccExp:
          (latmPocState.ccExpMonth.toString().length < 2 ? "0" + latmPocState.ccExpMonth : latmPocState.ccExpMonth) +
          "/" +
          latmPocState.ccExpYear,
        customerName: latmPocState.customerName,
        customerFatherName: latmPocState.customerFatherName,
        customerMotherName: latmPocState.customerMotherName,
        payChoice: latmPocState.payChoice,
        balanceAmount: balanceAmount,
        amount: amount && amount !== "0.00" && latmPocState.payChoice === "PAYANOTHERAMOUNT" ? amount : balanceAmount,
      };
      const newCardRow: SplitCardInterface = {
        customerName: latmPocState.customerName,
        cardBrand: latmPocState.cardBrand,
        lastFourDigits: lastFourDigits,
        ccExp:
          (latmPocState.ccExpMonth.toString().length < 2 ? "0" + latmPocState.ccExpMonth : latmPocState.ccExpMonth) +
          "/" +
          latmPocState.ccExpYear,
        amount: latmPocState.payChoice === "PAYANOTHERAMOUNT" ? amount : balanceAmount,
      };
      let cardAddedArrayVar: SplitCardInterface[] = latmPocState.splitCards.slice();
      cardAddedArrayVar.splice(0, 0, newCardRow);
      changedState["splitCards"] = cardAddedArrayVar;
      splitCardsRef.current = cardAddedArrayVar;
      let userCardsArrayVar: UserCardInterface[] = latmPocState.userCards.slice();
      userCardsArrayVar.splice(0, 0, newCardDetails);
      changedState["userCards"] = userCardsArrayVar;
      userCardsRef.current = userCardsArrayVar;
      dispatch(changeStateError(changedState));

      return {
        userCards: userCardsRef.current,
      };
    },
    [latmPocState, dispatch]
  );

  const clearFieldsAndState = useCallback(
    (clearDate: boolean) => {
      const payAmount = Number(latmPocState.amount).toFixed(2);
      const balanceAmount = Number(latmPocState.balanceAmount.replace("$", "")).toFixed(2);
      const newBalanceAmount: string | null = Number(parseFloat(balanceAmount) - parseFloat(payAmount))
        .toFixed(2)
        .toLocaleString();
      const changedState: { [index: string]: string | any } = { ...latmPocState };
      changedState["balanceAmount"] = newBalanceAmount;
      changedState["splitCards"] = splitCardsRef.current;
      changedState["userCards"] = userCardsRef.current;
      changedState["numberOfSplitCards"] = numberOfSplitCardsRef.current;
      if (clearDate) {
        const ccExpMonth: HTMLSelectElement = document.getElementById("ccExpMonth") as HTMLSelectElement;
        ccExpMonth.options[ccExpMonth.length] = new Option("  ", "  ");
        changedState["ccExpMonth"] = "  ";
        const ccExpYear: HTMLSelectElement = document.getElementById("ccExpYear") as HTMLSelectElement;
        ccExpYear.options[ccExpYear.length] = new Option("    ", "    ");
        changedState["ccExpYear"] = "    ";
        changedState["formSubmitted"] = true;
      } else {
        changedState["ccExpMonth"] = currentMonth;
        changedState["ccExpYear"] = currentYear;
        changedState["formSubmitted"] = false;
      }
      dispatch(resetForNewCard(changedState));
      creditCardMicroNumber.clear();
      cvvMicroSecurityCode.clear();
      buttonClickRef.current = false;
    },
    [dispatch, latmPocState]
  );

  const submitProfileAdd = useCallback(async () => {
    try {
      const expirationMonth = latmPocState.ccExpMonth;
      const expirationYear = latmPocState.ccExpYear;
      let resp;
      let tokenInfo;
      if (isCybersourceTimedout(browserStartTime)) {
        const { jti, lastFourDigits } = getTimedoutDummyToken();
        tokenInfo = { jti: jti, lastFourDigits: lastFourDigits, bin: "123456" };
      } else {
        resp = await createCybersourceToken(expirationMonth, expirationYear, paymentInfo.microform);
        tokenInfo = resp as { jti: string; lastFourDigits: string; bin: string };
      }
      // const profileAddRequest = buildProfileAddRequest(tokenInfo.jti);
      const profileAddRequest = buildCardTable(tokenInfo.jti, tokenInfo.lastFourDigits, tokenInfo.bin);
      clearFieldsAndState(true);
      cpgmuiRef.current?.response(profileAddRequest);
    } catch (err) {
      console.log(err);
    }
  }, [buildCardTable, latmPocState.ccExpMonth, latmPocState.ccExpYear, paymentInfo.microform, clearFieldsAndState]);

  const addNewCard = async (event: any) => {
    buttonClickRef.current = true;
    const validationResult = validateForm(false);
    if (validationResult.hasError) {
      cpgmuiRef.current?.validated(validationResult);
    } else {
      // setProcessing
      dispatch(setProcessing(true));

      //createToken();
      const expirationMonth = latmPocState.ccExpMonth;
      const expirationYear = latmPocState.ccExpYear;
      let resp;
      let tokenInfo;
      if (isCybersourceTimedout(browserStartTime)) {
        // const { token, jti, lastFourDigits } = getTimedoutDummyToken();
        const { jti, lastFourDigits } = getTimedoutDummyToken();
        tokenInfo = { jti: jti, lastFourDigits: lastFourDigits, bin: "123456" };
      } else {
        resp = await createCybersourceToken(expirationMonth, expirationYear, paymentInfo.microform);
        tokenInfo = resp as { jti: string; lastFourDigits: string; bin: string };
      }
      buildCardTable(tokenInfo.jti, tokenInfo.lastFourDigits, tokenInfo.bin);

      //clearFields clearState;
      clearFieldsAndState(false);

      // setProcessing
      dispatch(setProcessing(false));
    }
  };

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === CpgmuiEvents.execute) {
        const serviceOperation = event.data.payload.serviceOperation;

        if (serviceOperation === SERVICE_OPERATION.profileAdd) {
          buttonClickRef.current = true;
          const validationResult = validateForm(true);
          if (validationResult.hasError) {
            cpgmuiRef.current?.validated(validationResult);
          } else {
            if (serviceOperation === SERVICE_OPERATION.profileAdd) {
              submitProfileAdd();
            }
          }
        }
      } else if (event.data.type === CpgmuiEvents.validate) {
        const validationResult = validateForm(true);
        cpgmuiRef.current?.validated(validationResult);
      } else if (event.data.type === CpgmuiEvents.custom) {
        const customInfo = event.data.payload;
        if (customInfo.action === "testError") {
          const errorList = ["Something really bad happened.", "More bad news."];
          cpgmuiRef.current?.error(errorList);
        }
      }
    },
    [validateForm, submitProfileAdd]
  );

  useEffect(() => {
    if (cpgmuiState.unencryptedRequest && !setupTestDataRef.current) {
      const changedState: { [index: string]: string | any } = { ...latmPocState };
      if (balanceAmountRef.current) {
        changedState["balanceAmount"] = formatAsCurrency(balanceAmountRef.current.replace("$", ""));
      }
      if (isTestingApp(cpgmuiState.unencryptedRequest["app.TESTINGAPP"])) {
        changedState["ccExpMonth"] = TceppsTestData.ToBeAppTestData.LATMPOC["ppa.test.expirationMonth"];
        changedState["ccExpYear"] = TceppsTestData.ToBeAppTestData.LATMPOC["ppa.test.expirationYear"];
        dispatch(changeStateError(changedState));
      }
      setupTestDataRef.current = true;
    }
  }, [dispatch, latmPocState, cpgmuiState.unencryptedRequest]);

  useEffect(() => {
    // setCollapsed(true);
    window.addEventListener("message", windowMessageHandler, false);

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler]);

  //* setup microform...
  useEffect(() => {
    const updateCardBrand = (cardName: string, isValid: boolean, isEmpty: boolean) => {
      const cardBrand = getCardType(cardName);
      let creditCardInvalid = true;
      if (cardBrand && cardBrand.length > 1) {
        creditCardInvalid = false;
      }
      dispatch(
        setCardType({
          cardBrand: cardBrand || "",
          cardValid: isValid && !isEmpty ? true : false,
          creditCardInvalid: creditCardInvalid,
          creditCardMissing: isEmpty,
        })
      );
    };

    const initializeMicroform = async (captureContext: any) => {
      const flex = (await new (window as any).Flex(captureContext)) as Microform;
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "Card Number " });
      cvvMicroSecurityCode = await microform.createField("securityCode", { placeholder: "CVV *" });
      await creditCardMicroNumber.load("#creditCardNumber");
      await cvvMicroSecurityCode.load("#securityCode");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber) {
        creditCardMicroNumber.on("change", (data: any) => {
          if (data.card && data.card.length > 0) {
            updateCardBrand(data.card[0].name, data.valid, data.empty);
          } else {
            updateCardBrand("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          dispatch(loading(false));
          customerNameRef.current?.focus();
        });
      }

      if (cvvMicroSecurityCode) {
        cvvMicroSecurityCode.on("change", function (data: any) {
          dispatch(setCvv({ cvvValid: !data.empty && data.valid, cvvEmpty: !data.empty }));
        });
        cvvMicroSecurityCode.on("focus", function (data: any) {
          (document.querySelector("#securityCode") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#securityCode") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#securityCode") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        cvvMicroSecurityCode.on("blur", function (data: any) {
          (document.querySelector("#securityCode") as HTMLDivElement).style.outline = "none";
          (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#securityCode") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#securityCode") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
      }
    };

    const getCaptureContext = () => {
      const iframeUrl = getIframeUrl();
      // console.log("iframeUrl:" + iframeUrl);
      PaymentService.getCaptureContext(iframeUrl)
        .then((resp) => {
          const captureContext = resp.data.data;
          dispatch(contextLoadedAction(captureContext));
          if (!(window as any).Flex) {
            loadCybersourceJS(captureContext, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            setMicroFormLoadErrorMessage("AxiosError: call to initialize microForm failed. " + err.message + ". Please check console.");
          } else {
            setMicroFormLoadErrorMessage("Initialize microForm failed. " + err.message + ". Please check console.");
          }
        });
    };

    getCaptureContext();
  }, [dispatch]);

  const handleChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | any } = { ...latmPocState };
    changedState[field] = (event.target.value as string).toUpperCase();
    dispatch(changeStateError(changedState));
    if (field !== "ccExpMonth" && field !== "ccExpYear") {
      const errorState: { [index: string]: boolean } = { ...latmPocState.error };
      errorState[field + "Missing"] = event.target.value && event.target.value.length > 0 ? false : true;
      dispatch(updateError(errorState));
    }
  };

  const handleRadioChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | any } = { ...latmPocState };
    changedState[field] = (event.target.value as string).toUpperCase();
    changedState["payChoice"] = (event.target.value as string).toUpperCase();
    dispatch(changeStateError(changedState));
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (event.key === "Tab") {
      if (identifier === "ccExpMonth" && event.shiftKey) {
        if (creditCardMicroNumber) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "customerMotherName" && !event.shiftKey) {
        if (creditCardMicroNumber) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "ccExpYear" && !event.shiftKey) {
        if (cvvMicroSecurityCode) {
          cvvMicroSecurityCode.focus();
        }
      } else if (identifier === "payBalance" && event.shiftKey) {
        if (cvvMicroSecurityCode) {
          cvvMicroSecurityCode.focus();
        }
      }
    }
  };

  const getDateErrorMessage = (): string => {
    return "Date is expired. Please enter valid date.";
  };

  const getCardErrorMessage = (message: string): string => {
    if (microFormLoadErrorMessage) {
      return microFormLoadErrorMessage;
    } else {
      return message;
    }
  };

  const creditCardStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "155px", //"163px"
    margin: "0em",
    borderWidth: "1px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const securityStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "40px",
    margin: "0em",
    borderWidth: "1.5px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const tooltipStyle = {
    fontSize: "10pt",
    backgroundColor: "#ff6060 !important",
  };

  return (
    <>
      <div className="latmpoc-grid-container">
        <div className="item1">*Cardholder Name</div>
        <div className="item2 latmpoc-span2">*Father's Surname</div>
        <div className="item3 latmpoc-span2">Mother's Surname</div>
        <div className="item1">
          <input
            name="customerName"
            type="text"
            style={{ width: "150px" }}
            value={latmPocState.customerName}
            disabled={latmPocState.processing || latmPocState.formSubmitted}
            size={33}
            maxLength={30}
            tabIndex={1}
            ref={customerNameRef}
            onChange={handleChange}
            className={classNames({
              latmpocFieldError: latmPocState.error.customerNameMissing,
              fieldOK: !latmPocState.error.customerNameMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerName"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.customerNameMissing}
            content="Please enter card holder name."
          />
        </div>
        <div className="item2 latmpoc-span2">
          <input
            name="customerFatherName"
            type="text"
            style={{ width: "150px" }}
            value={latmPocState.customerFatherName}
            disabled={latmPocState.processing || latmPocState.formSubmitted}
            size={33}
            maxLength={30}
            tabIndex={2}
            onChange={handleChange}
            className={classNames({
              latmpocFieldError: latmPocState.error.customerFatherNameMissing,
              fieldOK: !latmPocState.error.customerFatherNameMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerFatherName"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.customerFatherNameMissing}
            content="Please enter Father's Surname."
          />
        </div>
        <div className="item6 latmpoc-span2">
          <input
            name="customerMotherName"
            type="text"
            style={{ width: "150px" }}
            value={latmPocState.customerMotherName}
            disabled={latmPocState.processing || latmPocState.formSubmitted}
            size={33}
            maxLength={30}
            tabIndex={3}
            onChange={handleChange}
            onKeyDown={(event) => onKeyDownHandler("customerMotherName", event)}
            className={classNames({
              latmpocFieldError: latmPocState.error.customerMotherNameMissing,
              fieldOK: !latmPocState.error.customerMotherNameMissing,
            })}
          />
        </div>
        <div className="item1 latmpoc-span2">*Credit or Debit Card Number</div>
        <div className="item2"></div>
        <div className="item3 latmpoc-span2"></div>
        <div className="item1">
          <div
            id="creditCardNumber"
            style={creditCardStyle}
            // tabIndex={4}
            className={classNames({
              latmpocFieldError: buttonClickRef.current && (latmPocState.error.creditCardInvalid || latmPocState.error.creditCardMissing),
              fieldOK: !(buttonClickRef.current || latmPocState.error.creditCardInvalid || latmPocState.error.creditCardMissing),
              latmpocTextInput: true,
            })}
          ></div>
          <Tooltip
            anchorSelect="#creditCardNumber"
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latmPocState.error.creditCardMissing}
            content={getCardErrorMessage("Please enter a credit card number.")}
          />
          <Tooltip
            anchorSelect="#creditCardNumber"
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latmPocState.error.creditCardInvalid}
            content={getCardErrorMessage("Please enter a visa or a mastercard or amex or discover card number.")}
          />
        </div>
        <div className="item2 latmpoc-span2"></div>
        <div className="item3 latmpoc-span2"></div>
        <div className="item1">*Expires (MM/YY)</div>
        <div className="item2">*Code(CVV)</div>
        <div className="item3"></div>
        <div className="item4 latmpoc-span2"></div>
        <div className="item1">
          <select
            tabIndex={5}
            id="ccExpMonth"
            name="ccExpMonth"
            value={latmPocState.ccExpMonth}
            onChange={handleChange}
            size={1}
            disabled={latmPocState.processing || latmPocState.formSubmitted}
            onKeyDown={(event) => onKeyDownHandler("ccExpMonth", event)}
            className={classNames({
              latmpocFieldError: latmPocState.error.ccExpExpired,
              fieldOK: !latmPocState.error.ccExpExpired,
            })}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <Tooltip
            anchorSelect="#ccExpMonth"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.ccExpExpired}
            content={getDateErrorMessage()}
          />
          <select
            tabIndex={6}
            id="ccExpYear"
            name="ccExpYear"
            value={latmPocState.ccExpYear}
            onChange={handleChange}
            style={{ width: "60px" }}
            size={1}
            onKeyDown={(event) => onKeyDownHandler("ccExpYear", event)}
            disabled={latmPocState.processing || latmPocState.formSubmitted}
            className={classNames({
              latmpocFieldError: latmPocState.error.ccExpExpired,
              fieldOK: !latmPocState.error.ccExpExpired,
              latmpocMarginLeft: true,
            })}
          ></select>
          <Tooltip
            anchorSelect="#ccExpYear"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.ccExpExpired}
            content={getDateErrorMessage()}
          />
        </div>
        <div className="item1">
          <div
            id="securityCode"
            style={securityStyle}
            // tabIndex={7}
            className={classNames({
              latmpocFieldError: buttonClickRef.current && (latmPocState.error.cvvInvalid || latmPocState.error.cvvMissing),
              fieldOK: !(buttonClickRef.current || latmPocState.error.cvvInvalid || latmPocState.error.cvvMissing),
            })}
          ></div>
          <Tooltip
            anchorSelect="#securityCode"
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latmPocState.error.cvvMissing}
            content="Please enter a Code(CVV)."
          />
          <Tooltip
            anchorSelect="#securityCode"
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latmPocState.error.cvvInvalid}
            content="Please enter a valid Code(CVV)."
          />
        </div>
        <div className="item3"></div>
        <div className="item4 latmpoc-span2"></div>

        <div className="item1 latmpoc-span5">
          <input
            type="radio"
            id="payBalance"
            name="payBalance"
            value="PAYBALANCE"
            tabIndex={8}
            required
            onChange={handleRadioChange}
            onKeyDown={(event) => onKeyDownHandler("payBalance", event)}
            disabled={latmPocState.processing || latmPocState.formSubmitted}
            checked={latmPocState.payChoice === "PAYBALANCE"}
          />
          <label htmlFor="payBalance1">Pay balance-{latmPocState.balanceAmount}</label>
        </div>
        <div className="item1 latmpoc-span5">
          <input
            type="radio"
            id="payAnotherAmount"
            name="payAnotherAmount"
            value="PAYANOTHERAMOUNT"
            tabIndex={8}
            onChange={handleRadioChange}
            disabled={maxSplitCardsRef.current || latmPocState.processing || latmPocState.formSubmitted}
            onKeyDown={(event) => onKeyDownHandler("payAnotherAmount", event)}
            checked={latmPocState.payChoice === "PAYANOTHERAMOUNT"}
          />
          <label htmlFor="payAnotherAmount">Pay Another Amount </label>
          {latmPocState.payChoice === "PAYANOTHERAMOUNT" ? " : " : ""}
          <input
            id="amount"
            name="amount"
            type="text"
            value={latmPocState.amount}
            hidden={latmPocState.payChoice !== "PAYANOTHERAMOUNT"}
            disabled={maxSplitCardsRef.current || latmPocState.processing || latmPocState.formSubmitted}
            size={12}
            maxLength={12}
            tabIndex={9}
            style={inputStyle}
            onChange={handleChange}
            className={classNames({
              latmpocFieldError:
                latmPocState.error.amountMissing || latmPocState.error.amountValueError || latmPocState.error.amountTotalError,
              fieldOK: !(latmPocState.error.amountMissing || latmPocState.error.amountValueError || latmPocState.error.amountTotalError),
            })}
          />
          <Tooltip
            anchorSelect="#amount"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.amountMissing}
            content="Please enter amount to be charged."
          />
          <Tooltip
            anchorSelect="#amount"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.amountValueError}
            content="Please enter a valid amount to be charged."
          />
          <Tooltip
            anchorSelect="#amount"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latmPocState.error.amountTotalError}
            content="Please add a card or amount totalling to the balance amount."
          />
          <input
            type="button"
            value="Add"
            tabIndex={10}
            hidden={latmPocState.payChoice !== "PAYANOTHERAMOUNT"}
            disabled={maxSplitCardsRef.current || latmPocState.processing || latmPocState.formSubmitted}
            className="latmpocMarginButton"
            onClick={addNewCard}
          />
        </div>
        <input id="cardBrand" name="cardBrand" type="hidden" value="" />
        <div className="item1 latmpoc-span5">
          <span style={{ color: "red", backgroundColor: "#dfe6df !important" }}>{microFormLoadErrorMessage}</span>
        </div>
      </div>
      <LatmpocSplitCardComponent />
    </>
  );
}
