export const AMOUNTTOTALSUMMARYMESSAGE = "Please add a card or amount totalling to the balance amount.";
export const AMOUNTVAIDMESSAGE = "Please enter a valid amount to be charged.";
export const AMOUNTMISSINGMESSAGE = "Please enter amount to be charged.";
export const CARDHOLDERNAMEMISSINGMESSAGE = "Please enter card holder name.";
export const CARDHOLDERFATHERNAMEMISSINGMESSAGE = "Please enter Father's Surname.";
export const CARDNUMBERMISSINGMESSAGE = "Please enter a credit card number.";
export const CARDNOTVALIDMESSAGE = "Please enter a visa or a mastercard or amex or discover card number.";
export const CVVMISSINGMESSAGE = "Please enter a Code(CVV).";
export const CVVNOTVALIDMESSAGE = "Please enter a valid Code(CVV).";
export const VAIDATEMESSAGE = "Date has expired. Please enter valid date.";
export const ZERODOLLOR = "$0.00";
export const PAYBALANCE = "PAYBALANCE";
export const PAYANOTHERAMOUNT = "PAYANOTHERAMOUNT";
