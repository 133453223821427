import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { LatmPocInterface } from "../../interface/redux-state/LatmPocInterface";

const initialState: LatmPocInterface = {
  loading: false,
  cardBrand: "",
  cardValid: false,
  cvvValid: false,
  cvvEmpty: false,
  ccExpMonth: "",
  ccExpYear: "",
  customerName: "",
  customerFatherName: "",
  customerMotherName: "",
  payChoice: "PAYBALANCE",
  balanceAmount: "",
  amount: "",
  splitCards: [],
  userCards: [],
  numberOfSplitCards: "0",
  processing: false,
  formSubmitted: false,
  error: {
    ccExpExpired: false,
    customerNameMissing: false,
    customerFatherNameMissing: false,
    customerMotherNameMissing: false,
    creditCardMissing: false,
    creditCardInvalid: false,
    cvvMissing: false,
    cvvInvalid: false,
    amountMissing: false,
    amountValueError: false,
    amountTotalError: false,
  },
};

export const latmPocSlice = createSlice({
  name: "latmpoc",
  initialState,
  reducers: {
    resetStateAndError: (state: Draft<LatmPocInterface>) => {
      state.loading = initialState.loading;
      state.cardBrand = initialState.cardBrand;
      state.cardValid = initialState.cardValid;
      state.cvvValid = initialState.cvvValid;
      state.cvvEmpty = initialState.cvvEmpty;
      state.ccExpMonth = initialState.ccExpMonth;
      state.ccExpYear = initialState.ccExpYear;
      state.customerName = initialState.customerName;
      state.customerFatherName = initialState.customerFatherName;
      state.customerMotherName = initialState.customerMotherName;
      state.error = initialState.error;
      state.payChoice = initialState.payChoice;
      state.balanceAmount = initialState.balanceAmount;
      state.amount = initialState.amount;
      state.splitCards = initialState.splitCards;
      state.userCards = initialState.userCards;
      state.numberOfSplitCards = initialState.numberOfSplitCards;
      state.processing = initialState.processing;
      state.formSubmitted = initialState.formSubmitted;
    },
    changeStateError: (state: Draft<LatmPocInterface>, action) => {
      state.loading = action.payload.loading;
      state.cardBrand = action.payload.cardBrand;
      state.cardValid = action.payload.cardValid;
      state.cvvValid = action.payload.cvvValid;
      state.cvvEmpty = action.payload.cvvEmpty;
      state.ccExpMonth = action.payload.ccExpMonth;
      state.ccExpYear = action.payload.ccExpYear;
      state.customerName = action.payload.customerName;
      state.customerFatherName = action.payload.customerFatherName;
      state.customerMotherName = action.payload.customerMotherName;
      state.error = action.payload.error;
      state.payChoice = action.payload.payChoice;
      state.balanceAmount = action.payload.balanceAmount;
      state.amount = action.payload.amount;
      state.splitCards = action.payload.splitCards;
      state.userCards = action.payload.userCards;
      state.numberOfSplitCards = action.payload.numberOfSplitCards;
      state.processing = action.payload.processing;
      state.formSubmitted = action.payload.formSubmitted;
    },
    setCvv: (state: Draft<LatmPocInterface>, action) => {
      state.cvvValid = action.payload.cvvValid;
      state.cvvEmpty = action.payload.cvvEmpty;
      state.error.cvvInvalid = !action.payload.cvvValid;
      state.error.cvvMissing = !action.payload.cvvEmpty;
    },
    setCardType: (state: Draft<LatmPocInterface>, action) => {
      state.cardValid = action.payload.cardValid;
      state.cardBrand = action.payload.cardBrand;
      state.error.creditCardInvalid = action.payload.creditCardInvalid;
      state.error.creditCardMissing = action.payload.creditCardMissing;
    },
    loading: (state: Draft<LatmPocInterface>, action) => {
      state.loading = action.payload;
    },
    updateError: (state: Draft<LatmPocInterface>, action) => {
      state.error = action.payload;
    },
    setProcessing: (state: Draft<LatmPocInterface>, action) => {
      state.processing = action.payload.processing;
    },
    resetForNewCard: (state: Draft<LatmPocInterface>, action) => {
      state.loading = initialState.loading;
      state.cardBrand = initialState.cardBrand;
      state.cardValid = initialState.cardValid;
      state.cvvValid = initialState.cvvValid;
      state.cvvEmpty = initialState.cvvEmpty;
      state.customerName = initialState.customerName;
      state.customerFatherName = initialState.customerFatherName;
      state.customerMotherName = initialState.customerMotherName;
      state.error = initialState.error;
      state.payChoice = initialState.payChoice;
      state.amount = initialState.amount;
      state.processing = initialState.processing;
      state.balanceAmount = action.payload.balanceAmount;
      state.splitCards = action.payload.splitCards;
      state.userCards = action.payload.userCards;
      state.numberOfSplitCards = action.payload.numberOfSplitCards;
      state.ccExpMonth = action.payload.ccExpMonth;
      state.ccExpYear = action.payload.ccExpYear;
      state.formSubmitted = action.payload.formSubmitted;
    },
  },
});

export const { resetStateAndError, changeStateError, setCvv, setCardType, loading, updateError, setProcessing, resetForNewCard } =
  latmPocSlice.actions;

export default latmPocSlice;
