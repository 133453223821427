import { Latm3PocComponent } from "./Latm3PocComponent";

const hrStyle = {
  color: "black",
  backgroundColor: "black",
  height: "2px",
};

export function Latm3PocScreen() {
  return (
    <>
      <hr style={hrStyle} />
      <Latm3PocComponent compPos={0} />
      <hr style={hrStyle} />
      <Latm3PocComponent compPos={1} />
      <hr style={hrStyle} />
      <Latm3PocComponent compPos={2} />
      <hr style={hrStyle} />
    </>
  );
}
