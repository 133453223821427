import "./latm3poc.css";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Latm3PocInterface, UserCardInterface } from "../../interface/redux-state/Latm3PocInterface";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState, KeyboardEvent } from "react";
import { isFirefox } from "react-device-detect";
import {
  resetState,
  changeState,
  setCvv,
  setCardType,
  loading,
  updateError,
  setProcessing,
  setChoice,
  resetForNewCard,
  setBalanceAmount,
  setAllBalanceAmounts,
  resetCard,
  clearCardField,
  setMicroform,
  initialUserCards,
} from "../../redux-store/reducer/latm3PocSlice";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import PaymentService from "../../service/paymentService";
import { initializeCpgmui, resetCpgmui } from "../../redux-store/reducer/cpgmuiSlice";
import { classNames, extractRequestParameters, getCardType, getIframeUrl, loadCybersourceJS } from "../../utils/utils";
import { Cpgmui, CpgmuiEvents } from "../../utils/Cpgmui";
import axios, { AxiosError } from "axios";
import { createCybersourceToken } from "../../utils/cybersourceUtil";
import { getYearsFromToday, isCybersourceTimedout, getTimedoutDummyToken } from "../../utils/calendarUtil";
import { formatAsCurrency, formatAmountWithDecimal } from "../../utils/numberUtil";
import {
  AMOUNTTOTALSUMMARYMESSAGE,
  AMOUNTMISSINGMESSAGE,
  AMOUNTVAIDMESSAGE,
  CARDHOLDERNAMEMISSINGMESSAGE,
  CARDHOLDERFATHERNAMEMISSINGMESSAGE,
  CARDNUMBERMISSINGMESSAGE,
  CARDNOTVALIDMESSAGE,
  CVVMISSINGMESSAGE,
  CVVNOTVALIDMESSAGE,
  VAIDATEMESSAGE,
  ZERODOLLOR,
  PAYBALANCE,
  PAYANOTHERAMOUNT,
} from "./Latm3PocMessage";

const errorFlags = {
  customerNameMissing: false,
  customerFatherNameMissing: false,
  customerMotherNameMissing: false,
  creditCardMissing: false,
  creditCardInvalid: false,
  cvvMissing: false,
  cvvInvalid: false,
  cardExpired: false,
  amountMissing: false,
  amountValueError: false,
  amountTotalError: false,
  cardNum: 0,
};
// const APP_NAME = "LATM3POC";

const SERVICE_OPERATION = {
  profileAdd: "profileAdd",
  clearIFrame: "clearIFrame",
};

type Microform = {
  microform: (options: any) => any;
};
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
    "font-size": "8pt",
    "font-family": '"Arial", sans-serif',
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
} as React.CSSProperties;

const inputStyle = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
} as React.CSSProperties;

const browserStartTime = new Date();
const currentMonthVar = browserStartTime.toLocaleString("en-US", { month: "2-digit" });
const currentYearVar = browserStartTime.toLocaleString("en-US", { year: "numeric" });

type Props = {
  compPos: number;
};

export function Latm3PocComponent(compPos: Props) {
  const latm3PocState = useSelector((state: any) => state.latm3poc) as Latm3PocInterface;
  const cpgmuiState = useSelector((state: any) => state.cpgmui);
  const dispatch = useDispatch();
  const cpgmuiRef = useRef<Cpgmui>();
  const setupTestDataRef = useRef<boolean>(false);
  const setupCcExpYearRef = useRef<boolean>(false);
  const balanceAmountRef = useRef<string>("");
  const customerNameRef = useRef<HTMLInputElement>(null);
  const customerMotherNameRef = useRef<HTMLInputElement>(null);
  const ccExpMonthRef = useRef<HTMLSelectElement>(null);
  const [microFormLoadErrorMessage, setMicroFormLoadErrorMessage] = useState("");
  const buttonClickRef = useRef<boolean>(false);
  const creditCardMicroNumberRef = useRef<any>(null);
  const cvvMicroSecurityCodeRef = useRef<any>(null);
  const userCardsArrayRef = useRef<Array<UserCardInterface>>([initialUserCards, initialUserCards, initialUserCards]);

  const { appKey } = useParams();

  //* initialize iframe -----------------------------
  useEffect(() => {
    const processRequest = async (req: any) => {
      const resp = await PaymentService.getDecryptRequest(req.app, req.encryptedRequestKey, req.encryptedRequest);
      req.unencryptedRequest = resp.data;
      cpgmuiRef.current = new Cpgmui(req.unencryptedRequest["tcepps.proxyUrl"]);
      balanceAmountRef.current = req.unencryptedRequest["pno.balanceAmount"];
      // dispatch(resetState());
      dispatch(initializeCpgmui(req));
      dispatch(
        setAllBalanceAmounts({
          balanceAmount0: formatAsCurrency(balanceAmountRef.current.replace("$", "")),
          balanceAmount1: ZERODOLLOR,
          balanceAmount2: ZERODOLLOR,
        })
      );
      if (!setupCcExpYearRef.current) {
        try {
          getYearsFromToday(11, document.getElementById("ccExpYear" + compPos.compPos) as HTMLSelectElement, false);
          setupCcExpYearRef.current = true;
        } catch (error) {}
      }
      return () => {
        dispatch(resetCpgmui());
        dispatch(resetState());
      };
    };

    const requestParameters = extractRequestParameters(appKey || "");
    processRequest(requestParameters).then(() => {
      cpgmuiRef.current?.iframeInitialized();
    });
  }, [dispatch, appKey, compPos.compPos]);

  // disable cards with ZERODOLAR payment amount
  const setHighCompAmounttoZero = useCallback(() => {
    for (let varIdx = compPos.compPos + 1; varIdx <= 2; varIdx++) {
      dispatch(resetCard({ cardNum: varIdx }));
    }
  }, [dispatch, compPos.compPos]);

  const checkPayAmount = useCallback(
    (cardNum: number): boolean => {
      const payAmount = formatAmountWithDecimal(latm3PocState.cardInfo[cardNum].amount).replace("$", "");
      const balanceAmount = formatAmountWithDecimal(latm3PocState.balanceAmounts[cardNum]).replace("$", "");
      if (!payAmount || isNaN(parseFloat(payAmount))) {
        return false;
      } else if (!balanceAmount || isNaN(parseFloat(balanceAmount))) {
        return false;
      } else if (parseFloat(payAmount) > parseFloat(balanceAmount)) {
        return false;
      }
      return true;
    },
    [latm3PocState.cardInfo, latm3PocState.balanceAmounts]
  );

  //* validateForm
  const validateForm = useCallback(
    (cardNum: number) => {
      let temp = errorFlags;
      let hasError = false;
      if (!latm3PocState.cardInfo[cardNum].customerName || latm3PocState.cardInfo[cardNum].customerName.trim().length === 0) {
        temp = { ...temp, customerNameMissing: true };
        hasError = true;
      }
      if (!latm3PocState.cardInfo[cardNum].customerFatherName) {
        temp = { ...temp, customerFatherNameMissing: true };
        hasError = true;
      }
      if (!latm3PocState.cardInfo[cardNum].cardBrand) {
        temp = { ...temp, creditCardInvalid: true };
        hasError = true;
      } else if (!latm3PocState.cardInfo[cardNum].cardValid) {
        temp = { ...temp, creditCardMissing: true };
        hasError = true;
      }
      if (!latm3PocState.cardInfo[cardNum].cvvValid) {
        temp = { ...temp, cvvMissing: true, cvvInvalid: true };
        hasError = true;
      } else if (!latm3PocState.cardInfo[cardNum].cvvEmpty) {
        temp = { ...temp, cvvMissing: true };
        hasError = true;
      }

      const expirationMonth = latm3PocState.cardInfo[cardNum].ccExpMonth;
      const expirationYear = latm3PocState.cardInfo[cardNum].ccExpYear;

      if (
        !expirationMonth ||
        !expirationYear ||
        currentYearVar > expirationYear ||
        (currentYearVar === expirationYear && currentMonthVar > expirationMonth)
      ) {
        temp = { ...temp, cardExpired: true };
        hasError = true;
      }

      if (latm3PocState.cardInfo[cardNum].payChoice === PAYANOTHERAMOUNT) {
        if (latm3PocState.cardInfo[cardNum].amount === "") {
          temp = { ...temp, amountMissing: true };
          hasError = true;
        } else if (!checkPayAmount(cardNum)) {
          temp = { ...temp, amountValueError: true };
          hasError = true;
        }

        if (!hasError) {
          if (latm3PocState.cardInfo[cardNum].payChoice === PAYANOTHERAMOUNT) {
            const cardAmount = formatAmountWithDecimal(latm3PocState.cardInfo[cardNum].amount).replace("$", "");
            const balanceAmount = formatAmountWithDecimal(latm3PocState.balanceAmounts[cardNum]).replace("$", "");
            if (parseFloat(balanceAmount) < parseFloat(cardAmount)) {
              temp = { ...temp, amountTotalError: true };
              hasError = true;
            }
          }
        }
      }

      if (hasError) {
        if (
          cardNum !== 0 &&
          latm3PocState.balanceAmounts[cardNum] === ZERODOLLOR // balance amount not same as original
        ) {
          // ignore the error
          hasError = false;
          temp = errorFlags;
          temp.creditCardInvalid = false;
        }
      }
      temp = { ...temp, cardNum: cardNum };
      dispatch(updateError(temp));
      return {
        hasError: hasError,
        errors: temp,
      };
    },
    [dispatch, latm3PocState, checkPayAmount]
  );

  // recalculate balance amount and resert the card information
  const resetBalanceAmounts = useCallback(
    (balanceAmount: string, payAmount: string) => {
      let newBalanceAmount = Number(parseFloat(balanceAmount) - parseFloat(payAmount))
        .toFixed(2)
        .toLocaleString();
      if (compPos.compPos === 0) {
        // recalculate balanceAmount of next card: 1
        dispatch(setBalanceAmount({ balanceAmount: "$" + newBalanceAmount, cardNum: 1 }));
        // recalculate balanceAmount of next card: 2
        dispatch(setBalanceAmount({ balanceAmount: ZERODOLLOR, cardNum: 2 }));

        setHighCompAmounttoZero();
      } else if (compPos.compPos === 1) {
        // recalculate balanceAmount of next card: 2
        dispatch(setBalanceAmount({ balanceAmount: "$" + newBalanceAmount, cardNum: 2 }));
        setHighCompAmounttoZero();
      }
    },
    [dispatch, compPos.compPos, setHighCompAmounttoZero]
  );

  // build return card information/
  const buildCardTable = useCallback(
    (jti: string, lastFourDigits: string, bin: string, cardNum: number) => {
      let amount = Number(latm3PocState.cardInfo[cardNum].amount.replace("$", "")).toFixed(2).toLocaleString();
      const balanceAmount = Number(latm3PocState.balanceAmounts[cardNum].replace("$", "")).toFixed(2).toLocaleString();

      const newCardDetails: UserCardInterface = {
        cardBrand: latm3PocState.cardInfo[cardNum].cardBrand,
        ccAccountNum: jti,
        lastFourDigits: lastFourDigits,
        bin: bin,
        ccExp:
          (latm3PocState.cardInfo[cardNum].ccExpMonth.toString().length < 2
            ? "0" + latm3PocState.cardInfo[cardNum].ccExpMonth
            : latm3PocState.cardInfo[cardNum].ccExpMonth) +
          "/" +
          latm3PocState.cardInfo[cardNum].ccExpYear,
        customerName: latm3PocState.cardInfo[cardNum].customerName,
        customerFatherName: latm3PocState.cardInfo[cardNum].customerFatherName,
        customerMotherName: latm3PocState.cardInfo[cardNum].customerMotherName,
        payChoice: latm3PocState.cardInfo[cardNum].payChoice,
        amount: amount && amount !== "0.00" && latm3PocState.cardInfo[cardNum].payChoice === PAYANOTHERAMOUNT ? amount : balanceAmount,
        balanceAmount: balanceAmount,
        cardNum: cardNum,
      };

      userCardsArrayRef.current[cardNum] = newCardDetails;
    },
    [latm3PocState.cardInfo, latm3PocState.balanceAmounts]
  );

  // clear card Fields
  const clearFieldsAndState = useCallback(() => {
    for (let varIdx = 0; varIdx <= 2; varIdx++) {
      const changedState: { [index: string]: string | any } = {
        ...latm3PocState.cardInfo[varIdx],
        cardNum: varIdx,
        formSubmitted: false,
      };
      changedState["ccExpMonth"] = currentMonthVar;
      changedState["ccExpYear"] = currentYearVar;
      dispatch(resetForNewCard(changedState));
      if (varIdx === 0) {
        dispatch(
          setAllBalanceAmounts({
            balanceAmount0: formatAsCurrency(balanceAmountRef.current),
            balanceAmount1: ZERODOLLOR,
            balanceAmount2: ZERODOLLOR,
          })
        );
      }
      dispatch(clearCardField({ cardNum: varIdx, newstate: true }));
    }
  }, [dispatch, latm3PocState.cardInfo]);

  // create Token and build return user card
  const submitProfileAdd = useCallback(
    async (cardNum: number) => {
      try {
        if (cardNum > 0) {
          if (latm3PocState.balanceAmounts[cardNum] === ZERODOLLOR) {
            return true;
          }
        }
        const expirationMonth = latm3PocState.cardInfo[cardNum].ccExpMonth;
        const expirationYear = latm3PocState.cardInfo[cardNum].ccExpYear;
        let resp;
        let tokenInfo;
        if (isCybersourceTimedout(browserStartTime)) {
          const { jti, lastFourDigits } = getTimedoutDummyToken();
          tokenInfo = { jti: jti, lastFourDigits: lastFourDigits, bin: "123456" };
        } else {
          resp = await createCybersourceToken(expirationMonth, expirationYear, latm3PocState.microform[cardNum]);
          tokenInfo = resp as { jti: string; lastFourDigits: string; bin: string };
        }
        // const profileAddRequest = buildProfileAddRequest(tokenInfo.jti);
        await buildCardTable(tokenInfo.jti, tokenInfo.lastFourDigits, tokenInfo.bin, cardNum);
      } catch (err) {
        console.log(err);
        // set creditCardInvalid
        const errorState: { [index: string]: boolean } = {
          ...latm3PocState.cardInfo[cardNum].error,
        };
        errorState["creditCardInvalid"] = true;
        dispatch(updateError({ ...errorState, cardNum: cardNum }));
        return false;
      }
      // dispatch(setFormSubmitted({ formSubmitted: false, cardNum: cardNum, formAction: SERVICE_OPERATION.profileAdd }));
      return true;
    },
    [dispatch, buildCardTable, latm3PocState.cardInfo, latm3PocState.microform, latm3PocState.balanceAmounts]
  );

  // enabel new Card(s)
  const addNewCard = async (event: any) => {
    buttonClickRef.current = true;
    const validationResult = validateForm(compPos.compPos);
    if (validationResult.hasError) {
      cpgmuiRef.current?.validated(validationResult);
    } else {
      // setProcessing
      dispatch(setProcessing(true));

      // recalculate balanceAmount for below cards
      let amount = Number(latm3PocState.cardInfo[compPos.compPos].amount.replace("$", "")).toFixed(2).toLocaleString();
      const balanceAmount = Number(latm3PocState.balanceAmounts[compPos.compPos].replace("$", "")).toFixed(2).toLocaleString();
      resetBalanceAmounts(balanceAmount, amount);

      // setProcessing
      dispatch(setProcessing(false));
    }
    buttonClickRef.current = false;
  };

  // get total of all amounts and check againts original balance amount
  const doesAmountMatchTotal = useCallback((): boolean => {
    let amount0 = latm3PocState.cardInfo[0].payChoice === PAYBALANCE ? latm3PocState.balanceAmounts[0] : latm3PocState.cardInfo[0].amount;
    let amount1 =
      latm3PocState.balanceAmounts[1] === ZERODOLLOR
        ? ZERODOLLOR
        : latm3PocState.cardInfo[1].payChoice === PAYBALANCE
        ? latm3PocState.balanceAmounts[1]
        : latm3PocState.cardInfo[1].amount;
    let amount2 =
      latm3PocState.balanceAmounts[2] === ZERODOLLOR
        ? ZERODOLLOR
        : latm3PocState.cardInfo[2].payChoice === PAYBALANCE
        ? latm3PocState.balanceAmounts[2]
        : latm3PocState.cardInfo[2].amount;
    amount0 = amount0 ? amount0.replace("$", "") : "0.00";
    amount1 = amount1 ? amount1.replace("$", "") : "0.00";
    amount2 = amount2 ? amount2.replace("$", "") : "0.00";
    let totalAmount = latm3PocState.balanceAmounts[0] ? latm3PocState.balanceAmounts[0].replace("$", "") : "0.00";
    if (parseFloat(totalAmount) === parseFloat(amount0) + parseFloat(amount1) + parseFloat(amount2)) {
      return true;
    } else {
      return false;
    }
  }, [latm3PocState.balanceAmounts, latm3PocState.cardInfo]);

  // // check if any of the fields have an error
  // const hasNoError = useCallback((): boolean => {
  //   let check0 =
  //     latm3PocState.cardInfo[0].error.customerNameMissing ||
  //     latm3PocState.cardInfo[0].error.customerFatherNameMissing ||
  //     latm3PocState.cardInfo[0].error.customerMotherNameMissing ||
  //     latm3PocState.cardInfo[0].error.creditCardMissing ||
  //     latm3PocState.cardInfo[0].error.creditCardInvalid ||
  //     latm3PocState.cardInfo[0].error.cvvMissing ||
  //     latm3PocState.cardInfo[0].error.cvvInvalid ||
  //     latm3PocState.cardInfo[0].error.cardExpired ||
  //     latm3PocState.cardInfo[0].error.amountMissing ||
  //     latm3PocState.cardInfo[0].error.amountValueError ||
  //     latm3PocState.cardInfo[0].error.amountTotalError;
  //   let check1 =
  //     latm3PocState.cardInfo[1].error.customerNameMissing ||
  //     latm3PocState.cardInfo[1].error.customerFatherNameMissing ||
  //     latm3PocState.cardInfo[1].error.customerMotherNameMissing ||
  //     latm3PocState.cardInfo[1].error.creditCardMissing ||
  //     latm3PocState.cardInfo[1].error.creditCardInvalid ||
  //     latm3PocState.cardInfo[1].error.cvvMissing ||
  //     latm3PocState.cardInfo[1].error.cvvInvalid ||
  //     latm3PocState.cardInfo[1].error.cardExpired ||
  //     latm3PocState.cardInfo[1].error.amountMissing ||
  //     latm3PocState.cardInfo[1].error.amountValueError ||
  //     latm3PocState.cardInfo[1].error.amountTotalError;
  //   let check2 =
  //     latm3PocState.cardInfo[2].error.customerNameMissing ||
  //     latm3PocState.cardInfo[2].error.customerFatherNameMissing ||
  //     latm3PocState.cardInfo[2].error.customerMotherNameMissing ||
  //     latm3PocState.cardInfo[2].error.creditCardMissing ||
  //     latm3PocState.cardInfo[2].error.creditCardInvalid ||
  //     latm3PocState.cardInfo[2].error.cvvMissing ||
  //     latm3PocState.cardInfo[2].error.cvvInvalid ||
  //     latm3PocState.cardInfo[2].error.cardExpired ||
  //     latm3PocState.cardInfo[2].error.amountMissing ||
  //     latm3PocState.cardInfo[2].error.amountValueError ||
  //     latm3PocState.cardInfo[2].error.amountTotalError;

  //   return !check0 || !check1 || !check2;
  // }, [latm3PocState.cardInfo]);

  const createUserResponse = useCallback(async () => {
    buttonClickRef.current = true;
    let submitResponse = true;
    // Step 1: validate form and create Cybersource token
    for (let varIdx = 0; varIdx <= 2 && submitResponse; varIdx++) {
      const validationResult = validateForm(varIdx);
      if (validationResult.hasError) {
        cpgmuiRef.current?.validated(validationResult);
        submitResponse = false;
        break;
      }
    }
    // check if all cards have no errors
    // Step 2: get temp Cybersource token
    for (let varIdx = 0; varIdx <= 2 && submitResponse; varIdx++) {
      submitResponse = await submitProfileAdd(varIdx);
      if (!submitResponse) {
        break;
      }
    }
    // if no error during token creation
    // Step 3: return Token back
    if (submitResponse) {
      // check if sum total of all amounts is same as original balance amount
      if (doesAmountMatchTotal()) {
        // collect all responses and send them
        cpgmuiRef.current?.response({ userCards: userCardsArrayRef.current });
      } else {
        // set amount don't match for cardidx 0
        const errorState: { [index: string]: boolean } = {
          ...latm3PocState.cardInfo[0].error,
        };
        errorState["amountTotalError"] = true;
        dispatch(updateError({ ...errorState, cardNum: 0 }));
      }
    }
    buttonClickRef.current = false;
  }, [dispatch, submitProfileAdd, doesAmountMatchTotal, validateForm, latm3PocState.cardInfo]);

  // setup message message handler. Handle at component index 0
  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (compPos.compPos === 0 && event.data.type === CpgmuiEvents.execute) {
        const serviceOperation = event.data.payload.serviceOperation;

        if (serviceOperation === SERVICE_OPERATION.profileAdd) {
          createUserResponse();
        } else if (serviceOperation === SERVICE_OPERATION.clearIFrame) {
          buttonClickRef.current = true;
          clearFieldsAndState();
          buttonClickRef.current = false;
        }
      } else if (event.data.type === CpgmuiEvents.validate) {
        for (let varIdx = 0; varIdx <= 2; varIdx++) {
          const validationResult = validateForm(varIdx);
          if (validationResult.hasError) {
            cpgmuiRef.current?.validated(validationResult);
          }
        }
      } else if (event.data.type === CpgmuiEvents.custom) {
        const customInfo = event.data.payload;
        if (customInfo.action === "testError") {
          const errorList = ["Something really bad happened.", "More bad news."];
          cpgmuiRef.current?.error(errorList);
        }
      }
    },
    [clearFieldsAndState, validateForm, createUserResponse, compPos.compPos]
  );

  // setup testing value for expiry date/year
  useEffect(() => {
    if (cpgmuiState.unencryptedRequest && !setupTestDataRef.current) {
      if (isTestingApp(cpgmuiState.unencryptedRequest["app.TESTINGAPP"])) {
        const changedState: { [index: string]: string | any } = { ...latm3PocState.cardInfo[compPos.compPos], cardNum: compPos.compPos };
        changedState["ccExpMonth"] = TceppsTestData.ToBeAppTestData.LATM3POC["ppa.test.expirationMonth"];
        changedState["ccExpYear"] = TceppsTestData.ToBeAppTestData.LATM3POC["ppa.test.expirationYear"];
        dispatch(changeState(changedState));
      }
      setupTestDataRef.current = true;
    }
  }, [dispatch, latm3PocState.cardInfo, cpgmuiState.unencryptedRequest, compPos.compPos]);

  // add window listener event
  useEffect(() => {
    window.addEventListener("message", windowMessageHandler, false);

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler]);

  // clear microform fields
  useEffect(() => {
    if (latm3PocState.clearCardField[compPos.compPos]) {
      creditCardMicroNumberRef.current.clear();
      cvvMicroSecurityCodeRef.current.clear();
      dispatch(clearCardField({ cardNum: compPos.compPos, newstate: false }));
    }
  }, [dispatch, latm3PocState.clearCardField, compPos.compPos]);

  //* setup microform...
  useEffect(() => {
    const updateCardBrand = (cardName: string, isValid: boolean, isEmpty: boolean) => {
      const cardBrand = getCardType(cardName);
      let creditCardInvalid = true;
      if (cardBrand && cardBrand.length > 1) {
        creditCardInvalid = false;
      }
      dispatch(
        setCardType({
          cardBrand: cardBrand || "",
          cardValid: isValid && !isEmpty ? true : false,
          creditCardInvalid: creditCardInvalid,
          creditCardMissing: isEmpty,
          cardNum: compPos.compPos,
        })
      );
    };

    const initializeMicroform = async (captureContext: any) => {
      const flex = (await new (window as any).Flex(captureContext)) as Microform;
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumberRef.current = await microform.createField("number", { placeholder: "Card Number " });
      cvvMicroSecurityCodeRef.current = await microform.createField("securityCode", { placeholder: "CVV *" });
      await creditCardMicroNumberRef.current.load("#creditCardNumber" + compPos.compPos);
      await cvvMicroSecurityCodeRef.current.load("#securityCode" + compPos.compPos);
      dispatch(setMicroform({ microform: microform, cardNum: compPos.compPos }));

      if (creditCardMicroNumberRef.current) {
        creditCardMicroNumberRef.current.on("change", (data: any) => {
          if (data.card && data.card.length > 0) {
            updateCardBrand(data.card[0].name, data.valid, data.empty);
          } else {
            updateCardBrand("", data.valid, data.empty);
          }
        });
        creditCardMicroNumberRef.current.on("focus", function (data: any) {
          (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.outline =
            "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        creditCardMicroNumberRef.current.on("blur", function (data: any) {
          (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.outline = "none";
          (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber" + compPos.compPos) as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
        creditCardMicroNumberRef.current.on("load", function (data: any) {
          dispatch(loading(false));
          if (compPos.compPos === 0) {
            customerNameRef.current?.focus();
          }
        });
      }

      if (cvvMicroSecurityCodeRef.current) {
        cvvMicroSecurityCodeRef.current.on("change", function (data: any) {
          dispatch(setCvv({ cvvValid: !data.empty && data.valid, cvvEmpty: !data.empty, cardNum: compPos.compPos }));
        });
        cvvMicroSecurityCodeRef.current.on("focus", function (data: any) {
          (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        cvvMicroSecurityCodeRef.current.on("blur", function (data: any) {
          (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.outline = "none";
          (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#securityCode" + compPos.compPos) as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
      }
    };

    const getCaptureContext = () => {
      const iframeUrl = getIframeUrl();
      // console.log("iframeUrl:" + iframeUrl);
      PaymentService.getCaptureContext(iframeUrl)
        .then((resp) => {
          const captureContext = resp.data.data;
          // dispatch(contextLoadedAction(captureContext));
          if (!(window as any).Flex) {
            loadCybersourceJS(captureContext, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            setMicroFormLoadErrorMessage("AxiosError: call to initialize microForm failed. " + err.message + ". Please check console.");
          } else {
            setMicroFormLoadErrorMessage("Initialize microForm failed. " + err.message + ". Please check console.");
          }
        });
    };

    getCaptureContext();
  }, [dispatch, compPos]);

  const handleChange = (event: any) => {
    const field = event.target.name as string;
    const fieldWithoutLastChar = field.slice(0, -1);
    const changedState: { [index: string]: string | any } = { ...latm3PocState.cardInfo[compPos.compPos], cardNum: compPos.compPos };
    changedState[fieldWithoutLastChar] = (event.target.value as string).toUpperCase();
    dispatch(changeState(changedState));
    if (fieldWithoutLastChar !== "ccExpMonth" && fieldWithoutLastChar !== "ccExpYear") {
      const errorState: { [index: string]: boolean } = {
        ...latm3PocState.cardInfo[compPos.compPos].error,
      };
      if (errorState[fieldWithoutLastChar + "Missing"] !== (event.target.value && event.target.value.length > 0 ? false : true)) {
        errorState[fieldWithoutLastChar + "Missing"] = event.target.value && event.target.value.length > 0 ? false : true;
        dispatch(updateError({ ...errorState, cardNum: compPos.compPos }));
      }
    }
  };

  const handleRadioChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | any } = { ...latm3PocState.cardInfo[compPos.compPos], cardNum: compPos.compPos };
    const choiceValue = (event.target.value as string).toUpperCase();
    const previousChoiceValue = latm3PocState.cardInfo[compPos.compPos].payChoice;

    if (previousChoiceValue !== choiceValue) {
      changedState[field] = choiceValue;
      changedState["payChoice"] = choiceValue;
      dispatch(changeState(changedState));

      if (choiceValue === PAYBALANCE) {
        // set below balances to $0.00
        if (compPos.compPos === 0) {
          //set [1] balance to $0.00
          //set [2] balance to $0.00
          dispatch(
            setAllBalanceAmounts({
              balanceAmount0: formatAsCurrency(balanceAmountRef.current.replace("$", "")),
              balanceAmount1: ZERODOLLOR,
              balanceAmount2: ZERODOLLOR,
            })
          );
          // set radiobutton PAYBALANCE and Amount to 0 for component 1 & 2
          setHighCompAmounttoZero();
        } else if (compPos.compPos === 1) {
          //set [2] balance to $0.00
          dispatch(setBalanceAmount({ balanceAmount: ZERODOLLOR, cardNum: 2 }));
          // set radiobutton PAYBALANCE and Amount to 0
          // set radiobutton PAYBALANCE and Amount to 0 for component 1 & 2
          setHighCompAmounttoZero();
        }
      }
    }
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement | HTMLInputElement | HTMLDivElement>) => {
    if (event.key === "Tab") {
      if (identifier === "payBalance" && !event.shiftKey) {
        dispatch(setChoice({ payChoice: PAYANOTHERAMOUNT, cardNum: compPos.compPos }));
      } else if (identifier === "payAnotherAmount" && event.shiftKey) {
        dispatch(setChoice({ payChoice: PAYBALANCE, cardNum: compPos.compPos }));
      } else if (identifier === "customerMotherName" && !event.shiftKey) {
        if (creditCardMicroNumberRef.current) {
          creditCardMicroNumberRef.current.focus();
        }
      } else if (identifier === "creditCardNumber" + compPos.compPos && event.shiftKey) {
        customerMotherNameRef.current?.focus();
      } else if (identifier === "creditCardNumber" + compPos.compPos && !event.shiftKey) {
        if (cvvMicroSecurityCodeRef.current) {
          cvvMicroSecurityCodeRef.current.focus();
        }
      } else if (identifier === "securityCode" + compPos.compPos && event.shiftKey) {
        if (creditCardMicroNumberRef.current) {
          creditCardMicroNumberRef.current.focus();
        }
      } else if (identifier === "securityCode" + compPos.compPos && !event.shiftKey) {
        ccExpMonthRef.current?.focus();
      } else if (identifier === "ccExpMonth" && event.shiftKey) {
        if (cvvMicroSecurityCodeRef.current) {
          cvvMicroSecurityCodeRef.current.focus();
        }
      }
    }
  };

  const getDateErrorMessage = (): string => {
    return VAIDATEMESSAGE;
  };

  const getCardErrorMessage = (message: string): string => {
    if (microFormLoadErrorMessage) {
      return microFormLoadErrorMessage;
    } else {
      return message;
    }
  };

  const getTabIndex = (indx: number) => {
    return compPos.compPos * 10 + indx;
  };

  const creditCardStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "155px", //"163px"
    margin: "0em",
    borderWidth: "1px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const securityStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "40px",
    margin: "0em",
    borderWidth: "1.5px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const tooltipStyle = {
    fontSize: "10pt",
    backgroundColor: "#ff6060 !important",
  };

  return (
    <>
      <div
        // className="latm3poc-grid-container"
        aria-disabled={latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR || latm3PocState.loading}
        className={
          latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR || latm3PocState.loading
            ? "latm3poc-grid-container latm3pocDisabled"
            : "latm3poc-grid-container"
        }
      >
        <div className="item1 latm3poc-span7">
          <span style={{ color: "blue", backgroundColor: "#dfe6df !important", textDecoration: "underline" }}>
            {compPos.compPos === 0 ? (
              <p style={{ margin: "0", padding: "0" }}>
                1<sup>st</sup> - Card Informaton
              </p>
            ) : compPos.compPos === 1 ? (
              <p style={{ margin: "0", padding: "0" }}>
                2<sup>nd</sup> - Card Informaton
              </p>
            ) : (
              <p style={{ margin: "0", padding: "0" }}>
                3<sup>rd</sup> - Card Informaton
              </p>
            )}
          </span>
        </div>

        <div className="item1">*Cardholder Name</div>
        <div className="item2 latm3poc-span3">*Father's Surname</div>
        <div className="item3 latm3poc-span2">Mother's Surname</div>
        <div className="item4 "></div>

        <div className="item1">
          <input
            name={"customerName" + compPos.compPos}
            id={"customerName" + compPos.compPos}
            type="text"
            style={{ width: "150px" }}
            value={latm3PocState.cardInfo[compPos.compPos].customerName}
            disabled={latm3PocState.processing}
            size={33}
            maxLength={30}
            tabIndex={getTabIndex(1)}
            ref={customerNameRef}
            onChange={handleChange}
            className={classNames({
              latm3pocFieldError:
                latm3PocState.cardInfo[compPos.compPos].error.customerNameMissing &&
                latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !latm3PocState.cardInfo[compPos.compPos].error.customerNameMissing ||
                latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
            })}
          />
          <Tooltip
            anchorSelect={"#customerName" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.customerNameMissing}
            content={CARDHOLDERNAMEMISSINGMESSAGE}
          />
        </div>
        <div className="item2 latm3poc-span3">
          <input
            name={"customerFatherName" + compPos.compPos}
            id={"customerFatherName" + compPos.compPos}
            type="text"
            style={{ width: "150px" }}
            value={latm3PocState.cardInfo[compPos.compPos].customerFatherName}
            disabled={latm3PocState.processing}
            size={33}
            maxLength={30}
            tabIndex={getTabIndex(2)}
            onChange={handleChange}
            className={classNames({
              latm3pocFieldError:
                latm3PocState.cardInfo[compPos.compPos].error.customerFatherNameMissing &&
                latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !latm3PocState.cardInfo[compPos.compPos].error.customerFatherNameMissing ||
                latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
            })}
          />
          <Tooltip
            anchorSelect={"#customerFatherName" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.customerFatherNameMissing}
            content={CARDHOLDERFATHERNAMEMISSINGMESSAGE}
          />
        </div>
        <div className="item6 latm3poc-span2">
          <input
            name={"customerMotherName" + compPos.compPos}
            id={"customerMotherName" + compPos.compPos}
            type="text"
            style={{ width: "150px" }}
            value={latm3PocState.cardInfo[compPos.compPos].customerMotherName}
            disabled={latm3PocState.processing}
            size={33}
            maxLength={30}
            tabIndex={getTabIndex(3)}
            onChange={handleChange}
            ref={customerMotherNameRef}
            onKeyDown={(event) => onKeyDownHandler("customerMotherName", event)}
            className={classNames({
              latm3pocFieldError: false,
              fieldOK: true,
            })}
          />
        </div>
        <div className="item4 "></div>

        <div className="item1 latm3poc-span2">*Credit or Debit Card Number</div>
        <div className="item2 latm3poc-span2">*Code(CVV)</div>
        <div className="item3 latm3poc-span2">*Expires (MM/YY)</div>
        <div className="item4 "></div>

        <div className="item1 latm3poc-span2">
          <div
            id={"creditCardNumber" + compPos.compPos}
            style={creditCardStyle}
            // tabIndex={getTabIndex(4)}
            className={classNames({
              latm3pocFieldError:
                !buttonClickRef.current &&
                (latm3PocState.cardInfo[compPos.compPos].error.creditCardInvalid ||
                  latm3PocState.cardInfo[compPos.compPos].error.creditCardMissing) &&
                latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !(
                  buttonClickRef.current ||
                  latm3PocState.cardInfo[compPos.compPos].error.creditCardInvalid ||
                  latm3PocState.cardInfo[compPos.compPos].error.creditCardMissing
                ) || latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
              latm3pocTextInput: true,
            })}
            onKeyDown={(event) => onKeyDownHandler("creditCardNumber" + compPos.compPos, event)}
          ></div>
          <Tooltip
            anchorSelect={"#creditCardNumber" + compPos.compPos}
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latm3PocState.cardInfo[compPos.compPos].error.creditCardMissing}
            content={getCardErrorMessage(CARDNUMBERMISSINGMESSAGE)}
          />
          <Tooltip
            anchorSelect={"#creditCardNumber" + compPos.compPos}
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latm3PocState.cardInfo[compPos.compPos].error.creditCardInvalid}
            content={getCardErrorMessage(CARDNOTVALIDMESSAGE)}
          />
        </div>

        <div className="item2 latm3poc-span2">
          <div
            id={"securityCode" + compPos.compPos}
            style={securityStyle}
            className={classNames({
              latm3pocFieldError:
                !buttonClickRef.current &&
                (latm3PocState.cardInfo[compPos.compPos].error.cvvInvalid || latm3PocState.cardInfo[compPos.compPos].error.cvvMissing) &&
                latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !(
                  buttonClickRef.current ||
                  latm3PocState.cardInfo[compPos.compPos].error.cvvInvalid ||
                  latm3PocState.cardInfo[compPos.compPos].error.cvvMissing
                ) || latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
            })}
            // onFocus={(event) => handleDivOnFocus("securityCode" + compPos.compPos, event)}
            onKeyDown={(event) => onKeyDownHandler("securityCode" + compPos.compPos, event)}
          ></div>
          <Tooltip
            anchorSelect={"#securityCode" + compPos.compPos}
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latm3PocState.cardInfo[compPos.compPos].error.cvvMissing}
            content={CVVMISSINGMESSAGE}
          />
          <Tooltip
            anchorSelect={"#securityCode" + compPos.compPos}
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!buttonClickRef.current || !latm3PocState.cardInfo[compPos.compPos].error.cvvInvalid}
            content={CVVNOTVALIDMESSAGE}
          />
        </div>

        <div className="item3 latm3poc-span2">
          <select
            tabIndex={getTabIndex(6)}
            id={"ccExpMonth" + compPos.compPos}
            name={"ccExpMonth" + compPos.compPos}
            value={latm3PocState.cardInfo[compPos.compPos].ccExpMonth}
            onChange={handleChange}
            size={1}
            disabled={latm3PocState.processing}
            onKeyDown={(event) => onKeyDownHandler("ccExpMonth", event)}
            ref={ccExpMonthRef}
            className={classNames({
              latm3pocFieldError:
                latm3PocState.cardInfo[compPos.compPos].error.cardExpired && latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !latm3PocState.cardInfo[compPos.compPos].error.cardExpired || latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
            })}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <Tooltip
            anchorSelect={"#ccExpMonth" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.cardExpired}
            content={getDateErrorMessage()}
          />
          <select
            tabIndex={getTabIndex(7)}
            id={"ccExpYear" + compPos.compPos}
            name={"ccExpYear" + compPos.compPos}
            value={latm3PocState.cardInfo[compPos.compPos].ccExpYear}
            onChange={handleChange}
            style={{ width: "60px" }}
            size={1}
            disabled={latm3PocState.processing}
            className={classNames({
              latm3pocFieldError:
                latm3PocState.cardInfo[compPos.compPos].error.cardExpired && latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !latm3PocState.cardInfo[compPos.compPos].error.cardExpired || latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
              latm3pocMarginLeft: true,
            })}
          ></select>
          <Tooltip
            anchorSelect={"#ccExpYear" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.cardExpired}
            content={getDateErrorMessage()}
          />
        </div>

        <div className="item1 latm3poc-span2">
          <input
            type="radio"
            id={"payBalance" + compPos.compPos}
            name={"payBalance" + compPos.compPos}
            value={PAYBALANCE}
            tabIndex={getTabIndex(8)}
            required
            onChange={handleRadioChange}
            onKeyDown={(event) => onKeyDownHandler("payBalance", event)}
            disabled={latm3PocState.processing}
            checked={latm3PocState.cardInfo[compPos.compPos].payChoice === PAYBALANCE}
          />
          <label htmlFor="payBalance1">Pay balance : {latm3PocState.balanceAmounts[compPos.compPos]}</label>
        </div>
        <div className="item1 latm3poc-span5">
          <input
            type="radio"
            id={"payAnotherAmount" + compPos.compPos}
            name={"payBalance" + compPos.compPos}
            value={PAYANOTHERAMOUNT}
            tabIndex={getTabIndex(8)}
            onChange={handleRadioChange}
            hidden={compPos.compPos === 2}
            disabled={
              latm3PocState.processing ||
              // latm3PocState.formSubmitted[compPos.compPos] ||
              latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR ||
              compPos.compPos === 2
            }
            onKeyDown={(event) => onKeyDownHandler("payAnotherAmount", event)}
            checked={latm3PocState.cardInfo[compPos.compPos].payChoice === PAYANOTHERAMOUNT}
          />
          <label htmlFor="payAnotherAmount" hidden={compPos.compPos === 2}>
            Pay Another Amount{" : "}
          </label>
          <input
            id={"amount" + compPos.compPos}
            name={"amount" + compPos.compPos}
            type="text"
            value={latm3PocState.cardInfo[compPos.compPos].amount.replace("$", "")}
            hidden={latm3PocState.cardInfo[compPos.compPos].payChoice !== PAYANOTHERAMOUNT}
            disabled={latm3PocState.processing}
            size={12}
            maxLength={12}
            tabIndex={getTabIndex(9)}
            style={inputStyle}
            onChange={handleChange}
            className={classNames({
              latm3pocFieldError:
                (latm3PocState.cardInfo[compPos.compPos].error.amountMissing ||
                  latm3PocState.cardInfo[compPos.compPos].error.amountValueError ||
                  latm3PocState.cardInfo[compPos.compPos].error.amountTotalError) &&
                latm3PocState.balanceAmounts[compPos.compPos] !== ZERODOLLOR,
              fieldOK:
                !(
                  latm3PocState.cardInfo[compPos.compPos].error.amountMissing ||
                  latm3PocState.cardInfo[compPos.compPos].error.amountValueError ||
                  latm3PocState.cardInfo[compPos.compPos].error.amountTotalError
                ) || latm3PocState.balanceAmounts[compPos.compPos] === ZERODOLLOR,
            })}
          />
          <Tooltip
            anchorSelect={"#amount" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.amountMissing}
            content={AMOUNTMISSINGMESSAGE}
          />
          <Tooltip
            anchorSelect={"#amount" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.amountValueError}
            content={AMOUNTVAIDMESSAGE}
          />
          <Tooltip
            anchorSelect={"#amount" + compPos.compPos}
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!latm3PocState.cardInfo[compPos.compPos].error.amountTotalError}
            content={AMOUNTTOTALSUMMARYMESSAGE}
          />
          <input
            type="button"
            id={"addAmount" + compPos.compPos}
            value="Add"
            tabIndex={getTabIndex(10)}
            hidden={latm3PocState.cardInfo[compPos.compPos].payChoice !== PAYANOTHERAMOUNT}
            disabled={latm3PocState.processing}
            className="latm3pocMarginButton"
            onClick={addNewCard}
          />
        </div>
        <input id={"cardBrand" + compPos.compPos} name={"cardBrand" + compPos.compPos} type="hidden" value="" />
        <div className="item1 latm3poc-span7">
          <span style={{ color: "red", backgroundColor: "#dfe6df !important" }}>{microFormLoadErrorMessage}</span>
        </div>
      </div>
    </>
  );
}
